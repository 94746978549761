<template lang="pug">
section.help-center__header-bar
  .container
    nuxt-link(:to="{ name: 'help-center' }" title="Help Center")
      img(src="/img/symbol.svg" width="64" height="64" alt="Help Center")

      h1 How can&nbsp;
        br.hidden-md-and-up
        | we help you?

    .help-center__search-bar
      el-input(type="search" v-model="q" @keypress.native.enter="search")
        el-button(slot="append" type="primary" @click="search") Search

    .help-center__brand-guide
      a(:href="BRAND_GUIDE_URL" target="_blank" title="Download Brand Guide")
        i.bx.bx-download.mr-1
        span Download Brand Guide to Pod Foods
</template>

<script>
import { BRAND_GUIDE_URL } from '~/settings'

export default {
  data () {
    return {
      BRAND_GUIDE_URL,
      q: ''
    }
  },

  computed: {
    query () {
      const q = this.$route.query.q || ''
      return q.trim()
    }
  },

  watch: {
    query: {
      immediate: true,
      handler (q) {
        this.q = q
      }
    }
  },

  methods: {
    search () {
      const q = this.q.trim()
      if (q.length === 0) return
      this.$router.push({
        name: 'help-center-search',
        query: {
          q: this.q
        }
      })?.catch(this.$debug.log)
    }
  }
}
</script>

<style lang="scss" scoped>
.help-center {
  &__header-bar {
    max-width: 560px;
    margin: 0 auto;
    text-align: center;
    h1 {
      font-size: 3rem;
      line-height: 1.25em;
      margin: .25em 0;
      font-weight: $--font-weight-normal;
    }
  }

  &__search-bar {
    margin: 2em 0;
  }
  &__brand-guide {
    color: $--color-primary;
    margin-top: 1.5em;
    a {
      display: flex;
      align-items: center;
      justify-content: center;
      > * {
        min-width: 0;
      }
      i {
        font-size: 1.5em;
      }
    }
  }
}
</style>
